import { defineStore } from "pinia";
import mushroom from "cem-probe-api";
import { Filter } from "mushroomjs";
let _ = require("lodash");
export const listMenu = defineStore({
  id: "listMenu",
  state: () => ({
    menuServices: [],
    menuUsecases: [],
    menuPhanCap: [],
  }),
  actions: {
    async getListMenuService() {
      try {
        const responseRoles = await mushroom.$auth.meAsync();
        const response = await mushroom.service.getAllAsync({
          filters: responseRoles.result.roles.includes("Admin")
            ? Filter.ne("disabled", true).ne("is_menu_hidden", true).build()
            : Filter.ne("is_menu_hidden", true).build(),
        });
        if (response.result) {
          response.result.forEach((item) => {
            if (item.avatar_id) {
              item.url = mushroom.$file.linkBuilder.thumb
                .id(item.avatar_id)
                .stretch(20, 20)
                .build();
            }
          });
          this.menuServices = response.result;
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },

    async getListMenuUsecases() {
      try {
        const responseRoles = await mushroom.$auth.meAsync();
        const response = await mushroom.usecase.getAllAsync({
          filters: responseRoles.result.roles.includes("Admin")
            ? Filter.ne("disabled", true).ne("is_menu_hidden", true).build()
            : Filter.ne("is_menu_hidden", true).build(),
        });
        if (response.result) {
          response.result.forEach((item) => {
            if (item.avatar_id) {
              item.url = mushroom.$file.linkBuilder.thumb
                .id(item.avatar_id)
                .stretch(20, 20)
                .build();
            }
          });
          this.menuUsecases = response.result;
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },
    async isMenuParent(menu) {
      if (!menu || !menu.id) return;
      try {
        const response = await mushroom.menu.listAsync({
          filters: Filter.eq("parent_id", menu.id).build(),
        });
        if (response.result?.length > 0) return true;
        return false;
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },
    async getListMenuPhanCap() {
      const responseRoles = await mushroom.$auth.meAsync();
      if (!responseRoles.result.roles.includes("Admin")) return;
      try {
        const response = await mushroom.menu.getAllAsync({
          filters: Filter.eq(
            "target_domain",
            localStorage.getItem("type_login") || "probe"
          )
            .ne("target_type", "static")
            .build(),
        });
        if (response.result?.length > 0) {
          let arr = [];
          let arrMenus = [];
          for (const menu of response.result) {
            const res = await mushroom.menu.listAsync({
              filters: Filter.eq("parent_id", menu.id).build(),
            });
            if (res.result.length === 0) arrMenus.push(menu);
          }

          let arrayParentId = arrMenus.filter((item) => item.parent_id);
          let arrayPromise = arrayParentId.map((item) =>
            mushroom.menu.getAllAsync({
              filters: Filter.eq("id", item.parent_id).build(),
            })
          );
          Promise.all(arrayPromise).then((res) => {
            res.forEach((item) => {
              arrMenus.forEach((menu) => {
                if (!menu.parent_id) {
                  let obj = {
                    id: "",
                    label: "",
                    targetId: "",
                    targetType: "",
                  };
                  obj.id =
                    menu.target_type +
                    ":" +
                    menu.id +
                    "-targetId:" +
                    menu.target_id;
                  obj.label = menu.label;
                  obj.targetId = menu.target_id;
                  obj.targetType = menu.target_type;
                  arr.push(obj);
                } else if (item.result[0].id === menu.parent_id) {
                  let obj = {
                    id: "",
                    label: "",
                    targetId: "",
                    targetType: "",
                  };
                  obj.id =
                    menu.target_type +
                    ":" +
                    menu.id +
                    "-targetId:" +
                    menu.target_id;
                  obj.label = item.result[0].visible
                    ? item.result[0].label + " - " + menu.label
                    : item.result[0].label;
                  obj.targetId = menu.target_id;
                  obj.targetType = menu.target_type;
                  arr.push(obj);
                }
              });
            });
            let a = _.uniqBy(arr, "label");
            this.menuPhanCap = _.sortBy(a, [
              function (o) {
                return o.label.toLowerCase();
              },
            ]);
          });
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },
  },
  getters: {
    showListMenuService(state) {
      return state.menuServices;
    },
    showListMenuUsecases(state) {
      return state.menuUsecases;
    },
    showListMenuPhanCap(state) {
      return state.menuPhanCap;
    },
  },
});
